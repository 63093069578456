import * as React from "react";
import ParticleComponent from "../components/particles";
import { getAwardPageContent } from "../services/pages.service";

import Layout from "../components/layout";
import Footer from "../components/Footer";
import award_img from "../images/numericaideas_award.png";

const AwardPage = ({ serverData }) => {
  const pageContent = serverData.content;
  let dataSEO = {
    title: "Numies Awards",
    description: "NumericaIdeas's Top Contributors Award",
    image: award_img,
    url: "https://www.numericaideas.com/award",
  };

  return (
    <Layout seoData={dataSEO}>
      <ParticleComponent>
        <div id="main" style={{ display: "flex", width: "100%" }}>
          <article
            id="award"
            className="active timeout"
            style={{ width: "90%" }}
          >
            <div
              key={`award`}
              id="award"
              dangerouslySetInnerHTML={{
                __html: pageContent,
              }}
            />
          </article>
        </div>
        <Footer/>
      </ParticleComponent>
    </Layout>
  );
};

export default AwardPage;

export async function getServerData() {
  return await getAwardPageContent();
}
